// @flow

import React from 'react'
import styled, { withTheme } from 'styled-components'

import { injectIntl, FormattedMessage } from 'react-intl'

import { media } from 'styles/utils'

import H1 from 'atoms/H1'
import Text from 'atoms/Text'

const Wrapper = styled.div`
  text-align: center;

  justify-content: center;
  color: ${props => props.theme.background};
  padding: 10px;
  ${media.large`
        order: 2;
    `}
`

const Step = styled.div`
  display: grid;
  grid-template-areas: 'icon title title' 'icon info info';
  text-align: left;
  grid-gap: 10px;
`

const Image = styled.img`
  grid-area: icon;
`

const StepTitle = styled.h4`
  padding: 0;
  line-height: 0;
  margin-bottom: 5px;
  font-size: 20px;
  margin-top: 15px;
  grid-area: title;
  color: ${props => props.theme.primary};
`

const StepText = styled(Text)`
  margin: 0;
  grid-area: info;
  margin-bottom: 20px;
  color: ${props => props.theme.background};
  line-height: 1.5;
`

const Strong = styled.strong`
  color: ${props => props.theme.highlight};
`

class Introduction extends React.Component {
  render() {
    const { intl, theme } = this.props
    const { messages } = intl
    const bitcoincash = (
      <span style={{ display: 'block' }}>{messages.bitcoincash}</span>
    )
    return (
      <Wrapper>
        <H1 thin style={{ width: '100%' }}>
          <FormattedMessage
            id="Introduction.title"
            defaultMessage={messages.Introduction.title}
            values={{ bitcoincash }}
          />
        </H1>
        <Step>
          <Image src={theme.step1} />
          <StepTitle>{messages.Introduction.step1}</StepTitle>
          <StepText>
            {messages.Introduction.step1text}
            <br />
            <Strong>{messages.Introduction.step1disclaimer}</Strong>
          </StepText>
        </Step>
        <Step>
          <Image src={theme.step2} />
          <StepTitle>{messages.Introduction.step2}</StepTitle>
          <StepText>{messages.Introduction.step2text}</StepText>
        </Step>
        <Step>
          <Image src={theme.step3} />
          <StepTitle>{messages.Introduction.step3}</StepTitle>
          <StepText>{messages.Introduction.step3text}</StepText>
        </Step>
      </Wrapper>
    )
  }
}

export default withTheme(injectIntl(Introduction))
