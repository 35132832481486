import React from 'react'
import { withTheme } from 'styled-components'

import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import numeral from 'numeral'
import H1 from 'atoms/H1'

import { getSelectedValue } from 'data/meta/selectors'
import { getSelectedGame } from 'data/gamerules/selectors'
import { getPrices } from 'data/prices/selectors'
import {
  Wrapper,
  ChanceSection,
  ChanceBox,
  ChanceTitle,
  ChanceStat,
  WinNumber,
  WinTitle,
  WinAmount,
  StyledBadger,
} from './styled'

class BetChances extends React.Component {
  render() {
    const {
      intl: { messages },
      theme,
      selectedValue,
      usdPrice,
      maxClickFn,
      minClickFn,
      betInfoData,
    } = this.props
    const { min, max, multiplier, betnumber, betmax, cashaddr } = betInfoData

    const winAmountBch = parseFloat((selectedValue * multiplier).toFixed(8))
    const winAmountUsd = numeral(
      (selectedValue * multiplier * usdPrice).toFixed(2)
    ).format('$0,0.00')

    return (
      <Wrapper>
        <H1 thin>Game Info</H1>
        <StyledBadger
          ref={ref => (this.badger = ref)}
          to={cashaddr}
          amount={selectedValue}
        >
          <WinAmount>
            <WinTitle>Play to win</WinTitle>
            <WinNumber>
              {winAmountBch} BCH (≈ {winAmountUsd} USD)
            </WinNumber>
          </WinAmount>
        </StyledBadger>
        <ChanceSection style={{ marginBottom: 10 }}>
          <ChanceBox color={theme.secondary}>
            <ChanceTitle>{messages.Betinfo.roll}</ChanceTitle>
            <ChanceStat>{betnumber}</ChanceStat>
          </ChanceBox>
          <ChanceBox color={theme.secondary}>
            <ChanceTitle>Maximum Roll</ChanceTitle>
            <ChanceStat>{betmax}</ChanceStat>
          </ChanceBox>
        </ChanceSection>
        <ChanceSection>
          <ChanceBox color={theme.tertiary} onClick={minClickFn} button>
            <ChanceTitle>{messages.Betinfo.minbet}</ChanceTitle>
            <ChanceStat>
              {min} {messages.bch}
            </ChanceStat>
          </ChanceBox>
          <ChanceBox color={theme.tertiary} onClick={maxClickFn} button>
            <ChanceTitle>{messages.Betinfo.maxbet}</ChanceTitle>
            <ChanceStat>
              {max} {messages.bch}
            </ChanceStat>
          </ChanceBox>
        </ChanceSection>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const selectedValue = getSelectedValue(state)
  const betInfoData = getSelectedGame(state)
  const { USD } = getPrices(state)
  return { selectedValue, betInfoData, usdPrice: USD }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(injectIntl(BetChances)))
