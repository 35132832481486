// @flow

import React from 'react'
import { withTheme } from 'styled-components'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import BadgerButton from 'components/BadgerButton'
import QRCode from 'qrcode-react'
import H1 from 'atoms/H1'

import { getSelectedValue } from 'data/meta/selectors'
import { getSelectedGame } from 'data/gamerules/selectors'
import {
  Wrapper,
  QRWrap,
  CopyNotify,
  CopyButton,
  OpenWalletButton,
  CashAddr,
} from './styled'

class PlayGame extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showCopyNotify: false }
    this.handleCopy = this.handleCopy.bind(this)
  }

  handleCopy() {
    this.setState({ showCopyNotify: true })
    setTimeout(() => {
      this.setState({ showCopyNotify: false })
    }, 3000)
  }

  render() {
    const { showCopyNotify } = this.state
    const { cashaddr, selectedValue, theme, intl } = this.props
    const { messages } = intl

    const sendBCH = `${cashaddr}?amount=${selectedValue}`

    return (
      <Wrapper>
        <H1 thin>
          Send BCH to play
          {/* <FormattedMessage
              id={messages.Betinfo.send}
              defaultMessage={messages.Betinfo.send}
              values={{ bitcoincash: bitcoincash }}
            /> */}
        </H1>

        <QRWrap>
          <QRCode
            value={sendBCH}
            logo={theme.qrlogo}
            size={240}
            logoWidth={75}
            logoHeight={75}
            bgColor={theme.background.css()}
            fgColor={theme.foreground.css()}
          />
          <CopyNotify show={showCopyNotify}>
            {messages.Betinfo.copynotify}
          </CopyNotify>
        </QRWrap>

        <CashAddr>{cashaddr}</CashAddr>
        <CopyToClipboard text={cashaddr} onCopy={this.handleCopy}>
          <CopyButton onClick={this.handleCopy}>
            {messages.Betinfo.copyaddress}
          </CopyButton>
        </CopyToClipboard>

        <OpenWalletButton as="a" href={sendBCH}>
          {messages.Betinfo.openaddress}
        </OpenWalletButton>

        <BadgerButton to={cashaddr} amount={selectedValue}>
          {messages.Betinfo.badgerplay}
        </BadgerButton>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const selectedValue = getSelectedValue(state)
  const { cashaddr } = getSelectedGame(state)
  return { selectedValue, cashaddr }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(injectIntl(PlayGame)))
