// @flow

import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { BadgerBase } from 'badger-components-react'

const Button = styled.button`
  margin: 0 10px;
  cursor: pointer;
  color: rgb(245, 147, 50);
  background: #222;
  border: 1px solid;
  padding: 9px;
  border-radius: 5px;
  display: block;
  border-color: rgb(245, 147, 50);
  animation: shadow-pulse 1s infinite;

  &:hover {
    animation: unset;
    background-color: rgb(245, 147, 50);
    color: rgb(255, 255, 255);
  }
`

class BadgerButton extends React.Component {
  render() {
    const {
      handleClick,
      step,
      children,
      intl: { messages },
    } = this.props

    let content = ''

    switch (step) {
      case 'login':
        content = 'Login to Badger'
        break
      case 'pending':
        content = 'Pending Payment'
        break
      default:
        content = children ? children : messages.Betinfo.badgerplay
        break
    }
    return (
      <Button {...this.props} onClick={handleClick}>
        {content}
      </Button>
    )
  }
}

export default BadgerBase(injectIntl(BadgerButton))
