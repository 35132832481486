import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Menu from 'components/Menu'
import Footer from 'components/Footer'

import Helmet from 'react-helmet'
import soundSource from 'assets/smb3_coin.wav'
import soundLose from 'assets/mario_die.wav'

import { getSfxStatus } from 'data/meta/selectors'
import { toggleSoundSFX } from 'data/meta/actions'
import { addGameToList } from 'data/gameslist/actions'
import { incrementCounter } from 'data/gamerules/actions'
import favicon from 'assets/favicon.ico'
import { WEBSOCKET_URL } from 'data/api'

const Wrapper = styled.div``

class LayoutDice extends React.Component {
  socket = null
  constructor(props) {
    super(props)

    this.state = {
      ping: '',
    }
    this.playSound = this.playSound.bind(this)
    this.playSoundLoose = this.playSoundLoose.bind(this)
    this.handleToggleSound = this.handleToggleSound.bind(this)
    this.handleSocket = this.handleSocket.bind(this)
    this.connectSocket = this.connectSocket.bind(this)
  }

  componentDidMount() {
    this.connectSocket()
  }

  handleSocket() {
    const { addGameToList, soundstatus, incrementCounter } = this.props

    const sendMessage = messageString => {
      this.socket.send(messageString)
    }

    this.socket.onopen = () => {
      console.log('Connected to websocket')

      this.setState({
        ping: setInterval(() => {
          sendMessage('ping')
        }, 60 * 1000),
      })
    }

    this.socket.onmessage = message => {
      const reply = JSON.parse(message.data)

      if (reply.type === 'event') {
        if (reply.game) {
          if (addGameToList) {
            addGameToList('gamelog', reply.game)
            incrementCounter()
            if (reply.game.win) {
              soundstatus && this.playSound()
              addGameToList('recentwins', reply.game)
            }
            if (!reply.game.win && soundstatus) {
              this.playSoundLoose()
            }
            if (reply.game.betnumber === 64 || reply.game.betnumber === 1024)
              addGameToList('rarewins', reply.game)
            if (reply.game.payout > 20) addGameToList('bigwins', reply.game)
          }
        }
      }
    }

    this.socket.onclose = () => {
      this.socket.close()
      console.log('Closed websocket')

      clearInterval(this.state.ping)
      this.connectSocket()
    }

    /* this.socket.onerror = () => {
      this.socket.close()
      console.log('Closed websocket')
      clearInterval(this.state.ping)
      this.connectSocket()
    } */
  }

  connectSocket() {
    setTimeout(() => {
      try {
        this.socket = new WebSocket(WEBSOCKET_URL)
        this.handleSocket()
      } catch (error) {
        this.connectSocket()
      }
    }, 1000)
  }

  playSound() {
    if (this.soundSrc.currentTime > 0) {
      this.soundSrc.currentTime = 0
    }
    this.soundSrc.play()
  }

  playSoundLoose() {
    if (this.soundLose.currentTime > 0) {
      this.soundLose.currentTime = 0
    }
    this.soundLose.play()
  }

  handleToggleSound(e) {
    const { toggleSoundSFX } = this.props
    toggleSoundSFX()
  }

  render() {
    const { children, locale, soundstatus } = this.props

    return (
      <Wrapper>
        <Helmet>
          <title>
            Satoshi Dice Bitcoin Games | Bitcoin Dice Game | SatoshiDICE
          </title>
          <meta
            name="description"
            content="Play Satoshi Dice bitcoin games, the epic BTC dice game is a provably fair on-chain Bitcoin Cash game. Take on the ghost of Satoshi, roll the dice to win."
          />
          <meta
            name="keywords"
            content="bitcoin games, bitcoin dice, btc dice, satoshi dice, satoshi bet, top bitcoin games, game bitcoin"
          />
          <link rel="icon" href={favicon} />
        </Helmet>
        <audio
          ref={soundSrc => {
            this.soundSrc = soundSrc
          }}
        >
          <source src={soundSource} type="audio/wav" />
        </audio>
        <audio
          ref={soundLose => {
            this.soundLose = soundLose
          }}
        >
          <source src={soundLose} type="audio/wav" />
        </audio>
        <Menu
          soundFn={this.handleToggleSound}
          sound={soundstatus}
          locale={locale}
          menuFor={'dice'}
        />
        {children}
        <Footer />
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const soundstatus = getSfxStatus(state)
  return { soundstatus }
}

const mapDispatchToProps = { toggleSoundSFX, addGameToList, incrementCounter }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutDice)
