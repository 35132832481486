import logoDice from 'assets/satoshidice-logo.png'
import logoStack from 'assets/satoshi-stack-logo.png'

import LayoutStack from 'components/LayoutStack'
import LayoutDice from 'components/LayoutDice'

const pages = {
  dice: {
    title: 'SatoshiDICE',
    logo: logoDice,
    layout: LayoutDice,
    url: '/',
    subPages: [
      { title: 'Rules', url: '/rules' },
      { title: 'Provably Fair', url: '/fair' },
    ],
  },
  stack: {
    title: 'SatoshiSTACK',
    logo: logoStack,
    layout: LayoutStack,
    url: 'https://satoshistack.cash',
    subPages: [{ title: 'Game History', url: '/stack/history' }],
  },
}

export const DEFAULT_USD_VALUE = 5

export { pages }
