import React from 'react'
import { withTheme } from 'styled-components'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import H1 from 'atoms/H1'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { IconCancel, IconCheck, IconTimer } from 'atoms/Icons'
import { getRecentGames } from 'data/gameslist/selectors'
import { getPrices } from 'data/prices/selectors'
import { formatBCHValue } from 'styles/utils'
import { Wrapper, RecentGameItem, Item, Value, RowLink } from './styled'

class RecentWins extends React.Component {
  shrinkTxHash(tx) {
    if (tx === undefined) return 'N/A'
    return tx.substring(0, Math.min(tx.length, 10)) + '...'
  }
  render() {
    const { recentwins, intl, price } = this.props
    const { messages } = intl

    const items = recentwins.map((item, i) => {
      const winResult = item.win ? (
        <IconCheck />
      ) : item.broadcasted ? (
        <IconTimer />
      ) : (
        <IconCancel />
      )
      let winResultText = ''

      if (item.win) {
        if (!item.broadcasted) {
          winResultText = 'Pending'
        } else {
          winResultText = messages.Winlist.win
        }
      } else {
        if (item.roll === -1) {
          winResultText = 'Invalid'
        } else {
          winResultText = messages.Winlist.lose
        }
      }
      return (
        <RowLink to={`/fair?id=${item.id}`} key={item.id}>
          <RecentGameItem>
            <Item area="win">
              <Value center>
                {winResult}
                <div>{winResultText}</div>
              </Value>
            </Item>
            <Item area="betamount">
              {messages.Winlist.betamount}
              <Value win={item.win} lose={!item.win}>
                {formatBCHValue(item.betAmount)} {messages.bch}
              </Value>
              <Value small>(${(item.betAmount * price).toFixed(2)} USD)</Value>
            </Item>
            <Item area="payout">
              {messages.Winlist.payoutamount}
              <Value win={item.win} lose={!item.win}>
                {formatBCHValue(item.payout)} {messages.bch}
              </Value>

              <Value small>(${(item.payout * price).toFixed(2)} USD)</Value>
            </Item>
            <Item area="bet">
              {messages.Winlist.bet}
              <Value>{item.bet}</Value>
            </Item>
            <Item area="roll">
              {messages.Winlist.roll}
              <Value>{item.roll === -1 ? 'N/A' : item.roll}</Value>
            </Item>
          </RecentGameItem>
        </RowLink>
      )
    })

    return (
      <Wrapper>
        <H1 thin>Recent Games</H1>
        <ReactCSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={0}
        >
          {items}
        </ReactCSSTransitionGroup>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const recentwins = getRecentGames(state)
  const { USD } = getPrices(state)
  return { recentwins, price: USD }
}

export default connect(mapStateToProps)(withTheme(injectIntl(RecentWins)))
