import styled, { css } from 'styled-components'
import { media } from 'styles/utils'

export const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: ${props => props.theme.background};
  text-align: center;
  margin: auto;
  width: 100%;
  ${media.medium`
      padding-left:35px;
      padding-right:35px;
  `};
`

export const SelectorContainer = styled.div`
  display: unset;
  ${media.medium`
      display: none;
  `};
  width: 100%;
  position: relative;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  background-color: ${props => props.theme.muted};
`

export const Selector = styled.div`
  position: relative;
  background-image: linear-gradient(
    to right bottom,
    ${props => props.theme.gradientStart},
    ${props => props.theme.gradientEnd}
  );
  background-attachment: fixed;
  width: 50px;
  font-size: 12px;
  margin: 5px;
  padding: 5px;
  color: white;
  border-radius: 40px;
  text-align: center;
  margin-bottom: 40px;
  cursor: pointer;
  box-shadow: 0 1px 3px ${props => props.theme.shadow};

  &:hover {
    box-shadow: none;
  }

  ${props =>
    props.selected &&
    css`
      background-image: none;
      background-color: ${props => props.theme.secondary};
      box-shadow: none;
    `} ${media.medium`
          width: 80px;
  `};
`

export const Multiplier = styled.div`
  position: absolute;
  color: ${props => props.theme.background};
  text-align: center;
  width: 50px;
  margin: 10px 0;
  ${media.medium`
      width: 80px;
  `};
`

export const SliderContainer = styled.div`
  margin-bottom: 100px;
  margin-top: 80px;
  padding: 0 40px;
  display: none;
  position: relative;
  ${media.medium`
  display: block;
`}
  .noUi-value {
    white-space: pre;
    background-image: linear-gradient(
      to right bottom,
      ${props => props.theme.gradientStart},
      ${props => props.theme.gradientEnd}
    );
    background-attachment: fixed;
    width: 50px;
    font-size: 12px;
    margin: 5px;
    width: 80px;
    padding: 5px;
    color: white;
    text-align: center;
    margin-bottom: 40px;
    cursor: pointer;

    ${media.large`
  border-radius: 40px;
  box-shadow: 0 1px 3px ${props => props.theme.shadow};
  `};
  }
  .noUi-tooltip {
    position: relative;
    height: 60px;
    width: 110px;
    box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px 0;
    border: rgba(0, 0, 0, 0.5) solid 1px;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px;
    padding: 5px;
    top: -70px;
    padding-top: 30px;
    padding-bottom: 10px;

    &:before {
      content: 'Multiplier';
      position: absolute;
      width: 110px;
      height: 20px;
      margin-left: -55px;
      left: 50%;
      top: 10px;
      font-weight: 400;
      font-size: 16px;
      box-sizing: border-box;
    }
  }
  .noUi-vertical {
    margin: auto;
    height: 500px;
  }

  .noUi-connect {
    background: ${props => props.theme.secondary};
    background-attachment: fixed;
    background-image: linear-gradient(
      to right,
      ${props => props.theme.gradientStart},
      ${props => props.theme.gradientEnd}
    );
  }
`

export const MultiplierContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.5);
  height: 60px;
  position: absolute;
  bottom: 170%;
  justify-content: space-between;
  border-radius: 3px;
  cursor: pointer;

  padding: 0 10px;
  left: -15px;
  right: -15px;
  ${media.large`
`}
`

export const MultiplierButton = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;

  ${media.large`
  padding: 10px;
`}
`

export const MultiplierTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: none;
  ${media.large`
display: block;
font-size: 16px;

`}
`

export const MultiplierValue = styled.div`
  font-weight: 700;
  font-size: 16px;
  ${media.large`

font-size: 22px;
`}
`
