import React from 'react'
import Layout from 'components/Layout'
import Hero from 'components/Hero'

class StackPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gamerules: {},
    }
  }

  render() {
    const { pageContext } = this.props
    const { locale } = pageContext
    return (
      <Layout locale={locale} layout="stack">
        <Hero>
          <div>gamestats</div>
          <div>gameboard</div>
        </Hero>
      </Layout>
    )
  }
}

export default StackPage
