// @flow

import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import H1 from 'atoms/H1'
import Slider from 'atoms/Slider'

import {
  Wrapper,
  SelectorContainer,
  Selector,
  Multiplier,
  SliderContainer,
  MultiplierContainer,
  MultiplierButton,
  MultiplierTitle,
  MultiplierValue,
} from './styled'

import { getGamerules } from 'data/gamerules/selectors'
import { setSelectedAddress } from 'data/meta/actions'
import { getSelectedAddress } from 'data/meta/selectors'

class ChanceSelectors extends React.Component {
  constructor(props) {
    super(props)

    this.handleSlideBlur = this.handleSlideBlur.bind(this)
    this.filterPips = this.filterPips.bind(this)
    this.formatPips = this.formatPips.bind(this)
    this.formatTooltip = this.formatTooltip.bind(this)
  }

  handleSlideBlur(value) {
    const { orderedOdds, setSelectedAddress } = this.props
    const betinfo = orderedOdds[Number(value[0]).toFixed(0)]

    setSelectedAddress(betinfo.address)
  }

  filterPips(value, type) {
    const { orderedOdds } = this.props
    const betinfo = orderedOdds ? orderedOdds[value] : { betnumber: 0 }
    return betinfo.betnumber
  }

  formatPips(value) {
    const { orderedOdds } = this.props
    const betinfo = orderedOdds[value]
      ? orderedOdds[value]
      : { betnumber: 1, betmax: 1 }
    return `Chance\n${((betinfo.betnumber / betinfo.betmax) * 100).toFixed(2)}%`
  }

  formatTooltip(value) {
    const { orderedOdds } = this.props
    const betinfo = orderedOdds[value]
    return betinfo.multiplier + 'x'
  }

  render() {
    const {
      orderedOdds,
      selectedAddress,
      intl,
      setSelectedAddress,
    } = this.props
    const { messages } = intl

    var selectedIndex = 0
    for (var i = 0; i < orderedOdds.length; i++) {
      if (orderedOdds[i].address === selectedAddress) {
        selectedIndex = i
        break
      }
    }

    return (
      <Wrapper>
        <H1 thin>{messages.Winlist.subtitle}</H1>

        <SliderContainer>
          <MultiplierContainer>
            {orderedOdds.map((item, index) => (
              <MultiplierButton
                onClick={() => {
                  setSelectedAddress(item.address)
                  this.NoUiSlider.slider.set(index)
                }}
                key={item.address}
              >
                <MultiplierTitle>Multiplier</MultiplierTitle>
                <MultiplierValue>{item.multiplier}x</MultiplierValue>
              </MultiplierButton>
            ))}
          </MultiplierContainer>
          {orderedOdds.length > 0 && (
            <Slider
              animate
              animationDuration={300}
              range={{
                min: [0],
                max: [orderedOdds.length - 1],
              }}
              start={[selectedIndex]}
              step={1}
              connect={[true, false]}
              behaviour="tap-drag"
              onChange={this.handleSlideBlur}
              ref={ref => (this.NoUiSlider = ref)}
              tooltips={{ to: this.formatTooltip }}
              clickablePips
              pips={{
                mode: 'range',
                filter: this.filterPips,
                density: orderedOdds.length - 1,
                format: {
                  to: this.formatPips,
                },
              }}
            />
          )}
        </SliderContainer>
        <SelectorContainer>
          {orderedOdds.map((item, idx) => {
            const winChance =
              ((item.betnumber / item.betmax) * 100).toFixed(2) + '%'

            return (
              <Selector
                key={item.betnumber + idx}
                selected={selectedAddress === item.address}
                onClick={() => {
                  setSelectedAddress(item.address)
                }}
              >
                <div>{messages.Winlist.chance}</div>
                <div>{winChance}</div>
                <Multiplier>{item.multiplier}x</Multiplier>
              </Selector>
            )
          })}
        </SelectorContainer>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const { orderedOdds } = getGamerules(state)
  const selectedAddress = getSelectedAddress(state)
  return {
    orderedOdds,
    selectedAddress,
  }
}

const mapDispatchToProps = {
  setSelectedAddress,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ChanceSelectors))
