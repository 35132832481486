import React from 'react'
import styled, { css, withTheme } from 'styled-components'
import { Link } from 'gatsby'
import { media } from 'styles/utils'
import langs from 'data/locales'
import { injectIntl } from 'react-intl'

import { IconGlobe, IconExpandDown } from 'atoms/Icons'

const StyledLink = styled(Link)`
  color: ${props => props.theme.background};
  text-decoration: none;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 20px;
  font-weight: 400;
  box-sizing: border-box;
  transition: color 250ms ease-in-out;
  align-self: center;
  &:hover {
    color: ${props => props.theme.tertiary};
  }
`

const LanguageMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

const SubMenu = styled.div`
  ${props =>
    props.showMenu
      ? css`
          max-height: 400px;
        `
      : css`
          max-height: 0px;
        `};
  display: flex;
  flex: 1;
  z-index: 9999;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  margin-top: 20px;
  top: 40px;
  background: ${props => props.theme.foreground};

  transition: all 0.3s ease-out;
  ${media.large`
        transition: none;
        position: absolute;
    `};
`

const SubMenuItem = styled(Link)`
  color: ${props => props.theme.background};
  padding: 5px 30px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: ${props => props.theme.shadow};
    color: ${props => props.theme.tertiary};
  }
`

class Languages extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLanguages: false,
    }

    this.handleShowLanguages = this.handleShowLanguages.bind(this)
  }
  handleShowLanguages(event) {
    event.preventDefault()
    this.setState({
      showLanguages: !this.state.showLanguages,
    })
  }

  render() {
    const { intl } = this.props
    const currentLocation = window.location.pathname
    const currLang = langs[intl.locale].locale

    const languages = Object.keys(langs).reduce((prev, curr) => {
      const lang = langs[curr]
      const pack = { path: lang.path, locale: lang.locale }

      return [...prev, pack]
    }, [])
    return (
      <LanguageMenu>
        <StyledLink as="div" onClick={this.handleShowLanguages}>
          <IconGlobe size={19} /> {currLang}
          <IconExpandDown size={19} />
        </StyledLink>
        <SubMenu showMenu={this.state.showLanguages}>
          {languages.map((lang, id) => {
            return (
              <SubMenuItem
                key={lang.path + id}
                to={`${
                  lang.path === 'en' ? '' : `/${lang.path}`
                }${currentLocation}`}
                onClick={() => {
                  this.setState({
                    showLanguages: !this.state.showLanguages,
                  })
                }}
              >
                {lang.locale}
              </SubMenuItem>
            )
          })}
        </SubMenu>
      </LanguageMenu>
    )
  }
}

export default injectIntl(withTheme(Languages))
