// @flow

import React from 'react'
import styled from 'styled-components'

import { media } from 'styles/utils'

const Wrapper = styled.div`
    font-size: 28px;
    font-weight: 700;
    line-height: 0.8;
    display: flex;
    justify-content: center;
    flex: 1;
    ${media.medium`
        font-size: 40px;
    `}
`

const Number = styled.span`
    margin: 2px;
    padding: 8px 6px;
    width: 34px;
    box-sizing: border-box;
    background: ${props => props.theme.foreground};
    border-radius: 5px;
    box-shadow: 0 1px 3px ${props => props.theme.shadow};
    &:nth-last-child(4n){
        background: none;
        width: 22px;
        margin: 0;
        margin-bottom: 0px;
        padding: 0;
        align-self: flex-end;
        color: ${props => props.theme.foreground};
        box-shadow: none;
    }

    ${media.large`
        padding:10px;
        width: 44px;
    `}
`

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

class FlipCounter extends React.Component {
    render() {
        const { count } = this.props;
        const numArray = count ? numberWithCommas(count).split('') : [];
        return (
            <Wrapper>
                {numArray.map((letter, idx) => <Number key={idx}>{letter}</Number>)}
            </Wrapper>
        )
    }
}

export default FlipCounter