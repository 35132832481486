import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'
import createMemoryHistory from 'history/createMemoryHistory'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import gamerules from './gamerules/reducer'
import meta from './meta/reducer'
import prices from './prices/reducer'
import gameslist from './gameslist/reducer'
import fairgames from './fair/reducer'

const hasWindow =
  typeof window === 'object' && window !== null && window.self === window
export const history = hasWindow ? createHistory() : createMemoryHistory()

// window.REDUX_STATE is for Server Side Rendering
const initialState =
  hasWindow && window.REDUX_STATE !== '__SERVER_REDUX_STATE__'
    ? window.REDUX_STATE
    : {}
// eslint-disable-next-line
hasWindow ? delete window.REDUX_STATE : null

const logger = ({ getState }) => {
  return next => action => {
    if (process.env.NODE_ENV === 'development') {
      console.log('DISPATCH ::', action.type)
    }
    return next(action)
  }
}
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['gamerules', 'meta'],
}

const middleware = [thunk, logger]

const rootReducer = combineReducers({
  gamerules,
  meta,
  prices,
  gameslist,
  fairgames,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  initialState,
  applyMiddleware(...middleware)
)

export const persistor = persistStore(store)

export default store
