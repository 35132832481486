// @flow

import React from 'react';
import styled, { withTheme } from 'styled-components';

//import { translate, Interpolate } from 'react-i18next'
import { injectIntl, FormattedMessage } from 'react-intl'

import Link from 'gatsby-link'
import { IconTwitter } from '../../atoms/Icons';


const Wrapper = styled.div`
    background-color: ${props => props.theme.foreground};
    justify-content: center;

`
const Content = styled.div`
    margin:auto;
    color: ${props => props.theme.background};
    text-align: center;
    padding: 10px;
`

const Logo = styled.img`
    max-height: 60px;
    max-width: 250px;
`

const Copyright = styled.div`
    font-size: 14px;
`
const Contact = styled.div`
    color: ${props => props.theme.background};
    font-size: 14px;
    padding: 10px;

`

const SocialLinks = styled.div`
    padding: 10px;
`


class Footer extends React.Component {
    render() {
        const { intl, theme } = this.props;
        const { messages } = intl
        const link = <a href={`mailto:${messages['contact-email']}`}>{messages['contact-email']}</a>
        return (
            <Wrapper>
                <Content>
                    <SocialLinks>
                        <a href="https://twitter.com/SatoshiDICE" target="_blank" rel="noopener noreferrer"><IconTwitter /></a>
                    </SocialLinks>
                    <Link to='/'><Logo src={theme.siteLogo} alt="SatoshiDICE" /></Link>
                    <Copyright>{messages.Footer.copyright}</Copyright>
                    <Contact><FormattedMessage id="Footer.contact" defaultMessage={messages.Footer.contact} values={{email: link}} /></Contact>
                </Content>
            </Wrapper>
        )
    }
}

export default withTheme(injectIntl(Footer))