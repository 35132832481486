export const getGamerules = state => state.gamerules
export const getSelectedGame = state => {
  const gamerules = state.gamerules
  let selectedAddress = state.meta.selectedAddress
  if (!gamerules.orderedOdds) return {}
  if (!selectedAddress) {
    const middle = Math.floor(gamerules.orderedOdds.length / 2)
    selectedAddress = gamerules.orderedOdds[middle].address
  }

  return gamerules.addresses[selectedAddress]
}
export const getGamescount = state => state.gamerules.gamescount
