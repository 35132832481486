// @flow

import React from 'react'
import styled from 'styled-components'

import Container from 'atoms/Container'

import { media } from 'styles/utils'
const Wrapper = styled.div`
  background-image: url(${props => props.theme.heroBg}),
    linear-gradient(
      to right,
      ${props => props.theme.gradientStart},
      ${props => props.theme.gradientEnd}
    );
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: calc(100vh - 370px);
  ${media.medium`
    padding: 10px;
  `}
`

class Hero extends React.Component {
  render() {
    return (
      <Wrapper>
        <Container>{this.props.children}</Container>
      </Wrapper>
    )
  }
}

export default Hero
