import styled, { css } from 'styled-components'
import { media } from 'styles/utils'

export const Row = styled.div`
  display: grid;
  grid-template-rows: auto;
  justify-content: center;
  padding: 10px;
  overflow: hidden;
  margin: 15px 0;
  background: ${props =>
    props.hover ? props.theme.background : props.theme.rowBackground};

  border: 1px solid ${props => props.theme.rowBorder};
  border-radius: 5px;
  box-shadow: 0 1px 3px ${props => props.theme.shadow};
  width: 100%;
  box-sizing: border-box;

  ${props =>
    props.cols &&
    css`
      ${props.cols === 8 &&
        css`
          grid-template-areas: 'betamount payout' 'time id' 'deposithash payouthash' 'bet roll';
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 10px;
          ${media.medium`
              grid-template-columns: repeat(4, 1fr);
              grid-template-areas: 'betamount payout time deposithash' 'id payouthash roll bet';
          `}
          ${media.large`
              grid-column-gap: 5px;
              grid-template-columns: repeat(2, 1.8fr) 1.5fr 0.5fr repeat(2, 1.5fr) 1fr 1fr;
              grid-template-areas: 'betamount payout time id deposithash payouthash bet roll';
          `}
        `}

      ${props.cols === 9 &&
        css`
          grid-template-areas: 'win win' 'betamount payout' 'time id' 'deposithash payouthash' 'bet roll';
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 10px;
          box-sizing: border-box;
          ${media.medium`
              grid-template-columns: repeat(5, 1fr);
              grid-template-areas: ' win betamount payout time deposithash' 'win id payouthash roll bet';
          `}
          ${media.large`
              grid-column-gap: 5px;
              grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr;
              grid-template-areas: 'win betamount payout time id deposithash payouthash bet roll';
          `}
        `}
    `}
`

export const Item = styled.div`
  display:flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  ${props =>
    props.area &&
    css`
      grid-area: ${props.area};
    `}

  ${media.medium`
      margin: auto;
  `}
  
  ${props => {
    if (props.area === 'betamount' || props.area === 'payout') {
      return css`
        color: ${props.theme.foreground};
        background: ${props.theme.rowBorder};
        border: 1px solid ${props.theme.foreground};
        border-radius: 5px;
      `
    }
  }}
`

export const Value = styled.strong`
  margin-top: 5px;
  overflow: hidden;
  display: flex;
  font-size: 12px;
  align-items: baseline;
  justify-content: center;
  white-space: pre;
  ${props =>
    props.center &&
    css`
      align-items: center;
      flex-direction: column;
    `}
  ${media.medium`
      font-size: 14px;
  `}
  ${media.large`
      word-wrap: break-word;
      overflow-y: hidden;
      overflow-x: hidden;
      max-width: 500px;
  `}
  
  ${props =>
    props.win &&
    css`
      color: green;
    `}
  
  ${props =>
    props.lose &&
    css`
      color: red;
    `}

  ${props =>
    props.small &&
    css`
      font-size: 13px;
    `}
`

export const Wrapper = styled.div`
  width: 100%;
  ${props =>
    props.selected
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  z-index: 1;
  margin-top: -1px;
  border: none;
  ${media.large`
      padding: 6px 12px;
  `}
`
