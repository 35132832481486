import React from 'react'

import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Hero from 'components/Hero'
import Container from 'atoms/Container'
import H1 from 'atoms/H1'
import GamesList from 'components/GamesList'
import { getGamerules } from 'data/gamerules/selectors'

const Wrapper = styled(Container)`
  display: block;
  margin: auto;
`

class RulesContent extends React.Component {
  render() {
    const { gamerules } = this.state
    const { messages } = this.props.intl
    const rulesContent = messages.Rules['rules-content']
    const faqContent = messages.Rules['faq-content']

    console.log(gamerules)
    return (
      <div>
        <Hero>
          <H1 thin>{messages.Rules['rules-title']}</H1>
        </Hero>
        <Wrapper>
          {rulesContent.map((item, idx) => {
            return (
              <div key={idx}>
                <h2>{item.title}</h2>
                {item.content.map((i, id) => (
                  <p key={id} dangerouslySetInnerHTML={{ __html: i }} />
                ))}
              </div>
            )
          })}
        </Wrapper>
        <Hero>
          <H1>{messages.Rules['faq-title']}</H1>
        </Hero>
        <Wrapper>
          {faqContent.map((item, idx) => {
            return (
              <div key={idx}>
                <h2>{item.title}</h2>
                {item.content.map((i, id) => (
                  <p key={id}>{i}</p>
                ))}
              </div>
            )
          })}
        </Wrapper>
        <Hero>
          <H1>Available Games</H1>
        </Hero>
        <GamesList gamerules={gamerules} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const gamerules = getGamerules(state)
  return { gamerules }
}
export default connect(mapStateToProps)(injectIntl(RulesContent))
