import { DEFAULT_USD_VALUE } from '../settings'

export const getSelectedAddress = state => {
  let selectedAddress = state.meta.selectedAddress
  if (!selectedAddress) {
    const middle = Math.floor(state.gamerules.orderedOdds.length / 2)
    selectedAddress = state.gamerules.orderedOdds[middle].address
  }
  return selectedAddress
}
export const getSelectedValue = state => {
  let selectedValue = state.meta.selectedValue
  if (!selectedValue) {
    selectedValue = (DEFAULT_USD_VALUE / state.prices.USD).toFixed(8)
  }
  return selectedValue
}
export const getMusicStatus = state => state.meta.soundMusic
export const getSfxStatus = state => state.meta.soundSFX
