import {
  SET_SELECTED_ADDRESS,
  SET_SELECTED_VALUE,
  TOGGLE_SOUND_MUSIC,
  TOGGLE_SOUND_SFX,
} from './constants'

export const initialState = {
  selectedAddress: '',
  selectedValue: 0,
  soundMusic: false,
  soundSFX: false,
}

const setSelectedAddress = (state, selectedAddress) => {
  return { ...state, selectedAddress }
}

const setSelectedValue = (state, selectedValue) => {
  return { ...state, selectedValue }
}

const toggleSoundMusic = state => {
  return {
    ...state,
    soundMusic: !state.soundMusic,
  }
}

const toggleSoundSFX = state => {
  return {
    ...state,
    soundSFX: !state.soundSFX,
  }
}

const meta = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ADDRESS:
      return setSelectedAddress(state, action.payload)
    case SET_SELECTED_VALUE:
      return setSelectedValue(state, action.payload)
    case TOGGLE_SOUND_MUSIC:
      return toggleSoundMusic(state)
    case TOGGLE_SOUND_SFX:
      return toggleSoundSFX(state)
    default:
      return state
  }
}

export default meta
