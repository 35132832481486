import React from 'react'
import { withTheme } from 'styled-components'
import Link from 'gatsby-link'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import numeral from 'numeral'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import { getExplorerUrl } from 'data/api'
import { formatBCHValue } from 'styles/utils'
import Loading from 'atoms/Loading'
import { IconCancel, IconCheck, IconTimer } from 'atoms/Icons'
import { Row, Item, Value, Wrapper } from './styled'
import { getPrices } from 'data/prices/selectors'

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})

// shrinks text of transaction to 10 chars or returns N/A if undefined

class TabContent extends React.Component {
  shrinkTxHash = tx => {
    if (tx === undefined) return 'N/A'
    return tx.substring(0, Math.min(tx.length, 10)) + '...'
  }

  componentDidMount() {
    this.update = setInterval(() => {
      if (this.props.selected) {
        this.forceUpdate()
      }
    }, 1000)
  }
  componentWillUnmount() {
    clearTimeout(this.update)
  }

  render() {
    const {
      data,
      showall,
      selected,
      intl: { locale, messages },
      theme,
      usdPrice,
    } = this.props

    const rowData = Object.keys(data || {})
      .map(i => data[i])
      .sort((a, b) => b.id - a.id)
      .slice(3)

    const location = '/' //window.location.pathname.substr(1)

    if (!selected) return <></>

    const items = rowData.map((item, idx) => {
      moment.locale(locale)
      const timeDiff = moment(item.timestampUnix).fromNow()
      const winResult = item.win ? (
        <IconCheck />
      ) : item.broadcasted ? (
        <IconTimer />
      ) : (
        <IconCancel />
      )

      let winResultText = ''
      if (item.win) {
        if (!item.broadcasted) {
          winResultText = 'Pending'
        } else {
          winResultText = messages.Winlist.win
        }
      } else {
        if (item.roll === -1) {
          winResultText = 'Invalid'
        } else {
          winResultText = messages.Winlist.lose
        }
      }
      /* const winResultText = item.win
        ? messages.Winlist.win
        : item.broadcasted
        ? 'Pending'
        : item.roll === -1
        ? 'Invalid'
        : messages.Winlist.lose
 */
      return (
        <Row key={idx} cols={showall ? 9 : 8}>
          <Item area="time">
            {messages.Winlist.time}
            <Value>{timeDiff}</Value>
          </Item>
          <Item area="id">
            {messages.Winlist.id}
            <Value>
              <Link to={`${location}fair?id=` + item.id}>{item.id}</Link>
            </Value>
          </Item>
          <Item area="deposithash">
            {messages.Winlist.tx}
            <a
              color={theme.tertiary}
              href={getExplorerUrl(item.depositTxHash)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {this.shrinkTxHash(item.depositTxHash)}
            </a>
          </Item>
          {item.win ? (
            <Item area="payouthash">
              {messages.Winlist.payout}
              <a
                color={theme.secondary}
                href={getExplorerUrl(item.payoutTxHash)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.shrinkTxHash(item.payoutTxHash)}
              </a>
            </Item>
          ) : (
            <Item area="payouthash">
              {messages.Winlist.payout}
              <Value>{this.shrinkTxHash(item.payoutTxHash)}</Value>
            </Item>
          )}
          <Item area="bet">
            {messages.Winlist.bet}
            <Value>{item.bet}</Value>
          </Item>
          <Item area="roll">
            {messages.Winlist.roll}
            <Value>{item.roll === -1 ? 'N/A' : item.roll}</Value>
          </Item>
          {showall && (
            <Item area="win">
              {messages.Winlist.result}
              <Value center>
                {winResult}
                <div>{winResultText}</div>
              </Value>
            </Item>
          )}
          <Item area="betamount">
            {messages.Winlist.betamount}
            <Value win={item.win} lose={!item.win}>
              {formatBCHValue(item.betAmount)} {messages.bch}
            </Value>
            {usdPrice && (
              <Value small>
                (${(item.betAmount * usdPrice).toFixed(2)} USD)
              </Value>
            )}
          </Item>
          <Item area="payout">
            {messages.Winlist.payoutamount}
            <Value win={item.win} lose={!item.win}>
              {formatBCHValue(item.payout)} {messages.bch}
            </Value>
            {usdPrice && (
              <Value small>
                (
                {numeral((item.payout * usdPrice).toFixed(2)).format('$0,0.00')}{' '}
                USD)
              </Value>
            )}
          </Item>
        </Row>
      )
    })

    return (
      <Wrapper selected={selected}>
        {rowData.length === 0 && <Loading />}
        <ReactCSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {items}
        </ReactCSSTransitionGroup>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const { USD } = getPrices(state)
  return { usdPrice: USD }
}

export default connect(mapStateToProps)(withTheme(injectIntl(TabContent)))
