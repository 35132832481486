// @flow

import React from 'react'
import styled, { css } from 'styled-components'
import { media } from 'styles/utils'
import Container from 'atoms/Container'

const Wrapper = styled(Container)`
  display: block;
  margin: auto;
`
const Row = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${media.medium`
    display: grid;

    grid-template-columns: 3fr 1fr 1fr 2fr;
    grid-template-areas: 'address betmax max multiplier' 'cashaddr betnumber min odds';
  `}
`

const Item = styled.div`
  ${props =>
    props.name &&
    css`
      grid-area: ${props.name};
    `}
  padding: 10px;
  overflow: hidden;
`

const Title = styled.div`
  font-weight: 700;
`

const Content = styled.div``

const GamesList = ({ gamerules }) => (
  <Wrapper>
    {gamerules.map((item, index) => {
      const odds = `${(item.betnumber / item.betmax) * 100}%`
      return (
        <Row key={item.address}>
          <Item name="address">
            <Title>Address</Title>
            <Content>{item.address}</Content>
          </Item>
          <Item name="cashaddr">
            <Title>CashAddr</Title>
            <Content>{item.cashaddr}</Content>
          </Item>
          <Item name="betmax">
            <Title>Max Roll</Title>
            <Content>{item.betmax}</Content>
          </Item>
          <Item name="betnumber">
            <Title>Bet Number</Title>
            <Content>{item.betnumber}</Content>
          </Item>
          <Item name="max">
            <Title>Max Bet</Title>
            <Content>{item.max}</Content>
          </Item>
          <Item name="min">
            <Title>Min Bet</Title>
            <Content>{item.min}</Content>
          </Item>
          <Item name="multiplier">
            <Title>Multiplier</Title>
            <Content>{item.multiplier}</Content>
          </Item>
          <Item name="odds">
            <Title>Odds</Title>
            <Content>{odds}</Content>
          </Item>
        </Row>
      )
    })}
  </Wrapper>
)

export default GamesList
